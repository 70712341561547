import * as React from "react"
import { Helmet } from "react-helmet"


import Seo from "../components/seo"

const Brands = () => (
  <section class="py-20"><div class="container px-4 mx-auto">
          <div class="flex flex-wrap items-center -mx-4">
            <div class="w-full lg:w-1/2 px-4 mb-12 md:mb-16 lg:mb-0">
              <div class="max-w-md">
                
                <h2 class="mt-8 mb-10 text-4xl font-semibold font-heading">Trusted by brands all over the world</h2>
                <p class="text-xl text-gray-500">We empower brands to make the world a better place. Byte by byte, line by line.</p>
              </div>
            </div>
            <div class="w-full lg:w-1/2 px-4">
              <div class="flex flex-wrap -m-4">
                <div class="w-1/2 md:w-1/3 p-4">
                  <div class="bg-gray-50 rounded-xl py-12">
                    {/* <img class="h-6 mx-auto" src="images/klases-pilkas.png" alt=""> */}

                    </div>
                </div>
                <div class="w-1/2 md:w-1/3 p-4">
                  <div class="bg-gray-50 rounded-xl py-12">
                    {/* <img class="h-6 mx-auto" src="images/Screenshot-2021-12-17-at-09-31-30-removebg-preview.png" alt=""> */}

                    </div>
                </div>
                <div class="w-1/2 md:w-1/3 p-4">
                  <div class="bg-gray-50 rounded-xl py-12">
                    {/* <img class="h-6 mx-auto" src="images/Sticker1-removebg-preview-1.png" alt=""> */}

                    </div>
                </div>
                <div class="w-1/2 md:w-1/3 p-4">
                  <div class="bg-gray-50 rounded-xl py-12">
                    {/* <img class="h-6 mx-auto" src="zeus-assets/logo/amazon-gray.svg" alt=""> */}

                    </div>
                </div>
                <div class="w-1/2 md:w-1/3 p-4">
                  <div class="bg-gray-50 rounded-xl py-12">
                    {/* <img class="h-6 mx-auto" src="zeus-assets/logo/netflix-gray.svg" alt=""> */}

                    </div>
                </div>
                <div class="w-1/2 md:w-1/3 p-4">
                  <div class="bg-gray-50 rounded-xl py-12">
                    {/* <img class="h-6 mx-auto" src="zeus-assets/logo/stripe-gray.svg" alt=""> */}

                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
)

const IndexPage = () => (
    <div className="font-body">
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap" rel="stylesheet" />
        </Helmet>
        <Seo title='PRCS - Developers' />
    <div>
      <nav class="flex justify-between items-center py-8 px-4 xl:px-10 top-0"><a class="text-lg font-semibold" href="#">
      <div className="font-display text-4xl">PRCS</div></a>
        <div class="block"><a class="inline-block py-3 px-8 text-sm leading-normal font-medium bg-gray-100 hover:bg-gray-300 text-gray-500 rounded transition duration-200" href="#contact">Contact Us</a></div>
      </nav>
    </div>

      <section class="relative pb-20 dark:bg-gray-500"><div class="relative container px-4 pt-12 mb-20 mx-auto text-center">
    <h2 class="mt-8 mb-8 lg:mb-12 text-4xl lg:text-6xl font-semibold">Websites PRCS on time</h2>
    <p class="max-w-3xl mx-auto mb-8 lg:mb-12 text-xl text-gray-500">Developers with a business mindset</p>
    </div>
    <div class="max-w-6xl px-4 mx-auto">
      <div class="flex flex-wrap -mx-4">
        <div class="w-full md:w-1/3 px-4 mb-4 md:mb-0">
        </div>
        <div class="w-full md:w-1/3 px-4 mb-4 md:mb-0">
        </div>
        <div class="w-full md:w-1/3 px-4">
        </div>
      </div>
    </div>
  <div class="navbar-mHenu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50 hidden">
      <div class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
      <nav class="relative flex flex-col py-6 px-6 w-full h-full bg-white border-r overflow-y-auto"><div class="flex items-center mb-8">
        <a class="mr-auto text-lg font-semibold leading-none" href="#">ss
          {/* <img class="h-7" src="zeus-assets/logo/logo-zeus-red.svg" alt="" width="auto"> */}

          </a>
        <button class="navbar-close">
          <svg class="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg></button>
        </div>
        <div>
          <ul><li class="mb-1"><a class="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="#">We are</a></li>dasd
            <li class="mb-1"><a class="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="#">We do</a></li>
            <li class="mb-1"><a class="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="#">We did</a></li>
            <li class="mb-1"><a class="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="#">Testimonials</a></li>
          </ul></div>
        <div class="mt-auto">
          <div class="pt-6"><a class="block py-3 text-center text-sm leading-normal rounded bg-red-50 hover:bg-red-200 text-red-500 font-semibold transition duration-200" href="#">Contact Us</a></div>
          <p class="mt-6 mb-4 text-sm text-center text-gray-500">
            <span>© 2021 All rights reserved.</span>
          </p>
        </div>
      </nav></div>
  </section>
    
      
    
      <section class="py-20"><div class="container mx-auto px-4">
          <div class="flex flex-wrap -mx-4">
            <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-12 lg:mb-20">
              <div class="flex">
                <span class="flex-shrink-0 mt-2 mr-6 text-gray-500">
                  <svg width="18" height="21" viewbox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.98457 19.606C8.98457 19.606 16.6566 17.283 16.6566 10.879C16.6566 4.474 16.9346 3.974 16.3196 3.358C15.7036 2.742 9.99057 0.75 8.98457 0.75C7.97857 0.75 2.26557 2.742 1.65057 3.358C1.03457 3.974 1.31257 4.474 1.31257 10.879C1.31257 17.283 8.98457 19.606 8.98457 19.606Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6.38599 9.87463L8.27799 11.7696L12.176 7.86963" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
                <div>
                  <h3 class="mb-4 text-2xl font-semibold font-heading">Business first</h3>
                  <p class="text-base text-gray-500">We focus on creating solution that enhance businesses. We don't start a project without understand it's business first.</p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-12 lg:mb-20">
              <div class="flex">
                <span class="flex-shrink-0 mt-2 mr-6 text-gray-500">
                  <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 0.750122C15.108 0.750122 19.25 4.89112 19.25 10.0001C19.25 15.1081 15.108 19.2501 10 19.2501C4.891 19.2501 0.75 15.1081 0.75 10.0001C0.75 4.89112 4.891 0.750122 10 0.750122Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.99512 6.20422V10.6232" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.995 13.7961H10.005" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
                <div>
                  <h3 class="mb-4 text-2xl font-semibold font-heading">Tech Savy</h3>
                  <p class="text-base text-gray-500">We focus on always providing the most relevant technological solutions for our clients.</p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-12 lg:mb-20">
              <div class="flex">
                <span class="flex-shrink-0 mt-2 mr-6 text-gray-500">
                  <svg width="18" height="20" viewbox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.4235 7.4478V5.3008C13.4235 2.7878 11.3855 0.7498 8.87249 0.7498C6.35949 0.7388 4.31349 2.7668 4.30249 5.2808V5.3008V7.4478" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M12.6832 19.2496H5.04224C2.94824 19.2496 1.25024 17.5526 1.25024 15.4576V11.1686C1.25024 9.07359 2.94824 7.37659 5.04224 7.37659H12.6832C14.7772 7.37659 16.4752 9.07359 16.4752 11.1686V15.4576C16.4752 17.5526 14.7772 19.2496 12.6832 19.2496Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.86304 12.2028V14.4238" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
                <div>
                  <h3 class="mb-4 text-2xl font-semibold font-heading">Transparent</h3>
                  <p class="text-base text-gray-500">All our development cicle is shared with the client to have a fully transparent process. Know when and how your project is being built.</p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-6 lg:mb-0">
              <div class="flex">
                <span class="flex-shrink-0 mt-2 mr-6 text-gray-500">
                  <svg width="22" height="20" viewbox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.9026 6.8512L12.4593 10.4642C11.6198 11.1302 10.4387 11.1302 9.59919 10.4642L5.11841 6.8512" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M15.9089 19C18.9502 19.0084 21 16.5095 21 13.4384V6.57001C21 3.49883 18.9502 1 15.9089 1H6.09114C3.04979 1 1 3.49883 1 6.57001V13.4384C1 16.5095 3.04979 19.0084 6.09114 19H15.9089Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
                <div>
                  <h3 class="mb-4 text-2xl font-semibold font-heading">Scalable</h3>
                  <p class="text-base text-gray-500">Our solutions are meant to be scalable. In case your business grows 10X, your project will be able to grow as well - we took care of it.</p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-6 lg:mb-0">
              <div class="flex">
                <span class="flex-shrink-0 mt-2 mr-6 text-gray-500">
                  <svg width="22" height="18" viewbox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8877 7.89673C18.2827 7.70073 19.3567 6.50473 19.3597 5.05573C19.3597 3.62773 18.3187 2.44373 16.9537 2.21973" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18.7285 11.2502C20.0795 11.4522 21.0225 11.9252 21.0225 12.9002C21.0225 13.5712 20.5785 14.0072 19.8605 14.2812" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M10.8867 11.6638C7.67273 11.6638 4.92773 12.1508 4.92773 14.0958C4.92773 16.0398 7.65573 16.5408 10.8867 16.5408C14.1007 16.5408 16.8447 16.0588 16.8447 14.1128C16.8447 12.1668 14.1177 11.6638 10.8867 11.6638Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M10.8869 8.88788C12.9959 8.88788 14.7059 7.17888 14.7059 5.06888C14.7059 2.95988 12.9959 1.24988 10.8869 1.24988C8.7779 1.24988 7.0679 2.95988 7.0679 5.06888C7.0599 7.17088 8.7569 8.88088 10.8589 8.88788H10.8869Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.88484 7.89673C3.48884 7.70073 2.41584 6.50473 2.41284 5.05573C2.41284 3.62773 3.45384 2.44373 4.81884 2.21973" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.044 11.2502C1.693 11.4522 0.75 11.9252 0.75 12.9002C0.75 13.5712 1.194 14.0072 1.912 14.2812" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
                <div>
                  <h3 class="mb-4 text-2xl font-semibold font-heading">Client focused</h3>
                  <p class="text-base text-gray-500">We love technologies but we love people even more. We focus on offering solutions that are human friendly, so anyone could understand it.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section class="relative py-20"><div class="container px-4 mx-auto">
          <div class="max-w-2xl mb-28 mx-auto text-center">
            <h2 class="mt-8 mb-10 text-4xl font-semibold font-heading">In systems we trust</h2>
            <p class="max-w-lg mx-auto text-xl text-gray-500">We have developed our systems that allow us to deliver bespoke software with confidence.</p>
          </div>
          <div class="flex flex-wrap -mx-3 -mb-10 lg:-mb-16">
            <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-10 lg:mb-16">
              <div class="relative pt-16 pb-12 px-8 bg-gray-50 rounded-lg text-center hover:bg-white hover:shadow-2xl">
                <span class="absolute top-0 inset-x-0 -mt-6 flex justify-center items-center w-16 h-16 mx-auto rounded-full text-white bg-gray-400">1</span>
                <h3 class="mb-6 text-2xl font-semibold font-heading">Business analysis</h3>
                <p class="text-gray-500">We care about your business and take time to analyse it's in and outs. This helps us understand your competitive landscape as well as precise needs.</p>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-10 lg:mb-16">
              <div class="relative pt-16 pb-12 px-8 bg-gray-50 rounded-lg text-center hover:bg-white hover:shadow-2xl">
                <span class="absolute top-0 inset-x-0 -mt-6 flex justify-center items-center w-16 h-16 mx-auto rounded-full text-white bg-gray-400">2</span>
                <h3 class="mb-6 text-2xl font-semibold font-heading">MVP stage</h3>
                <p class="text-gray-500">We develop a fast and scalable solution that you can show to your clients ASAP. We focus on fast time-to-market and data-driven analysis.</p>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-10 lg:mb-16">
              <div class="relative pt-16 pb-12 px-8 bg-gray-50 rounded-lg text-center hover:bg-white hover:shadow-2xl">
                <span class="absolute top-0 inset-x-0 -mt-6 flex justify-center items-center w-16 h-16 mx-auto rounded-full text-white bg-gray-400">3</span>
                <h3 class="mb-6 text-2xl font-semibold font-heading">Growth</h3>
                <p class="text-gray-500">After getting feedbacks from analytical tools and you, we improve the product. Don't worry about scalability - we already took care of it!</p>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-10">
              
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-10">
              
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-10">
              
            </div>
          </div>
        </div>
      </section>
    
      <section id="contact" class="pt-20 px-12"><div class="container px-4 mx-auto mb-20">
          <div class="flex flex-wrap items-center justify-between -mx-4">
            <div class="w-full lg:w-auto px-4 mb-12 lg:mb-0">
              <div class="max-w-lg">
                <h2 class="text-4xl font-semibold font-heading">Coffee ? Tea ?</h2>
              </div>
            </div>
            <div class="w-full lg:w-auto px-4">
              <h3 class="mb-4 text-2xl font-semibold font-heading">Address</h3>
              <p class="mb-10 lg:mb-8 text-gray-500">A. Goštauto g. 8-335, Vilnius, Lithuania</p>
              <h3 class="mb-4 text-2xl font-semibold font-heading">Contacts</h3>
              <p class="text-gray-500">dovydas@prcs.dev</p>
              <p class="text-gray-500">+370 656 45 000</p>
            </div>
          </div>
        </div>
      </section>
  </div>
)

export default IndexPage
